.account__name {
    font-size: 32px;
    font-weight: 500;
}

.account__chip {
    background-color: #CA5252;
    
}


.account__verified {
    background-color: #52CA6F;
}