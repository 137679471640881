@import '@telegram-apps/telegram-ui/dist/styles.css';

* {
  margin:0;
  padding:0;
  box-sizing: border-box;
  user-select: none;
}

.dark {
  --tg-color-scheme: dark;
  --tg-theme-accent-text-color: #07D800;
  --tg-theme-bg-color: #111111;
  --tg-theme-button-color: white;
  --tg-theme-button-text-color: #000000;
  --tg-theme-destructive-text-color: #ef5b5b;
  --tg-theme-header-bg-color: #102025;
  --tg-theme-hint-color: #b1c3d5;
  --tg-theme-link-color: #00c2ed;
  --tg-theme-secondary-bg-color: black;
  --tg-theme-section-bg-color: #18323b;
  --tg-theme-section-header-text-color: #b1c3d5;
  --tg-theme-subtitle-text-color: #b1c3d5;
  --tg-theme-text-color: #ffffff;
  --tg-viewport-height: 100vh;
  --tg-viewport-stable-height: 100vh;
  --tgui--accent_text_color: #07D800;
  --tgui--base--section--box_shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
  --tgui--bg_color: #111111;
  --tgui--black: #000;
  --tgui--border--width: 0.5px;
  --tgui--button_color: white;
  --tgui--button_text_color: #000000;
  --tgui--caption1--font_size: 13px;
  --tgui--caption1--line_height: 16px;
  --tgui--caption2--font_size: 11px;
  --tgui--caption2--line_height: 13px;
  --tgui--card_bg_color: #242424;
  --tgui--destructive_background: rgba(255,35,35,0.02);
  --tgui--destructive_text_color: #ef5b5b;
  --tgui--divider: hsla(0,0%,100%,0.05);
  --tgui--font_weight--accent1: 700;
  --tgui--font_weight--accent2: 600;
  --tgui--font_weight--accent3: 400;
  --tgui--font-family: system-ui,-apple-system,BlinkMacSystemFont,"Roboto","Apple Color Emoji","Helvetica Neue",sans-serif;
  --tgui--green: #32e55e;
  --tgui--headline--font_size: 19px;
  --tgui--headline--line_height: 24px;
  --tgui--large_title--font_size: 34px;
  --tgui--large_title--line_height: 41px;
  --tgui--link_color: #00c2ed;
  --tgui--outline--own: ;
  --tgui--outline: hsla(0,0%,100%,0.1);
  --tgui--plain_background: hsla(0,0%,100%,0.08);
  --tgui--plain_foreground: hsla(0,0%,100%,0.95);
  --tgui--primary_code_highlight: #2990ff;
  --tgui--quartenary_bg_color: #2f2f2f;
  --tgui--safe_area_inset_bottom: 0px;
  --tgui--secondary_bg_color: black;
  --tgui--secondary_code_highlight: #e937ed;
  --tgui--secondary_fill: rgba(41,144,255,0.15);
  --tgui--secondary_hint_color: #78797e;
  --tgui--section_bg_color: #18323b;
  --tgui--section_header_text_color: #b1c3d5;
  --tgui--segmented_control_active_bg: #2f2f2f;
  --tgui--skeleton: hsla(0,0%,100%,0.03);
  --tgui--subheadline1--font_size: 16px;
  --tgui--subheadline1--line_height: 21px;
  --tgui--subheadline2--font_size: 15px;
  --tgui--subheadline2--line_height: 20px;
  --tgui--subtitle_text_color: #b1c3d5;
  --tgui--surface_dark: rgba(56,56,56,0.85);
  --tgui--surface_primary: hsla(0,0%,9%,0.95);
  --tgui--tertiary_bg_color: #2a2a2a;
  --tgui--tertiary_code_highlight: #5ae536;
  --tgui--text_color: #ffffff;
  --tgui--text--font_size: 17px;
  --tgui--text--line_height: 22px;
  --tgui--title1--font_size: 28px;
  --tgui--title1--line_height: 34px;
  --tgui--title2--font_size: 24px;
  --tgui--title2--line_height: 28px;
  --tgui--title3--font_size: 20px;
  --tgui--title3--line_height: 25px;
  --tgui--toast_accent_color: #55a6ff;
  --tgui--white: #fff;
  --tgui--z-index--overlay: 3;
  --tgui--z-index--simple: 1;
  --tgui--z-index--skeleton: 2;
  --tooltip_background_dark: rgba(0,0,0,0.85);
  
  /* Приоритетные переменные ниже */
  --tg-theme-subtitle-text-color: #b1c3d5 !important;
  --tg-theme-section-bg-color: #18323b !important;
  --tg-theme-link-color: #00c2ed !important;
  --tg-theme-section-header-text-color: #b1c3d5 !important;
  --tg-theme-header-bg-color: #102025 !important;
  --tg-theme-destructive-text-color: #ef5b5b !important;
  --tg-color-scheme: dark !important;
  --tg-theme-bg-color: #111111 !important;
  --tg-theme-button-text-color: #000000 !important;
  --tg-theme-button-color: white !important;
  --tg-theme-secondary-bg-color: black !important;
  --tg-theme-hint-color: #b1c3d5 !important;
  --tg-theme-accent-text-color: #07D800 /*#00c2ed*/ !important;
  --tg-theme-text-color: #ffffff !important;
  --tg-viewport-height: 100vh!important;
  --tg-viewport-stable-height: 100vh!important;
  
}


.light {
  --tg-color-scheme: light;
  --tg-theme-accent-text-color: #07D800;
  --tg-theme-bg-color: #f5f5f5; /* Светлый фон */
  --tg-theme-button-color: black; /* Кнопки станут черными */
  --tg-theme-button-text-color: white; /* Текст на кнопках станет белым */
  --tg-theme-destructive-text-color: #ef5b5b;
  --tg-theme-header-bg-color: #e0e0e0; /* Светлый заголовок */
  --tg-theme-hint-color: #4a4a4a; /* Текст с подсказками станет темнее */
  --tg-theme-link-color: #00c2ed;
  --tg-theme-secondary-bg-color: #ffffff; /* Вторичный фон - белый */
  --tg-theme-section-bg-color: #f0f0f0; /* Фон секций светлый */
  --tg-theme-section-header-text-color: #4a4a4a; /* Цвет текста заголовков секций */
  --tg-theme-subtitle-text-color: #4a4a4a; /* Текст подзаголовков */
  --tg-theme-text-color: #000000; /* Основной текст - черный */
  --tg-viewport-height: 100vh;
  --tg-viewport-stable-height: 100vh;
  --tgui--accent_text_color: #07D800;
  --tgui--base--section--box_shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
  --tgui--bg_color: #f5f5f5; /* Основной фон светлый */
  --tgui--black: #000; /* Черный остается черным */
  --tgui--border--width: 0.5px;
  --tgui--button_color: black; /* Кнопки станут черными */
  --tgui--button_text_color: white; /* Текст на кнопках станет белым */
  --tgui--caption1--font_size: 13px;
  --tgui--caption1--line_height: 16px;
  --tgui--caption2--font_size: 11px;
  --tgui--caption2--line_height: 13px;
  --tgui--card_bg_color: #ffffff; /* Карты светлые */
  --tgui--destructive_background: rgba(255,35,35,0.02);
  --tgui--destructive_text_color: #ef5b5b;
  --tgui--divider: hsla(0,0%,0%,0.05); /* Линии разделения станут темнее */
  --tgui--font_weight--accent1: 700;
  --tgui--font_weight--accent2: 600;
  --tgui--font_weight--accent3: 400;
  --tgui--font-family: system-ui,-apple-system,BlinkMacSystemFont,"Roboto","Apple Color Emoji","Helvetica Neue",sans-serif;
  --tgui--green: #32e55e;
  --tgui--headline--font_size: 19px;
  --tgui--headline--line_height: 24px;
  --tgui--large_title--font_size: 34px;
  --tgui--large_title--line_height: 41px;
  --tgui--link_color: #00c2ed;
  --tgui--outline: hsla(0, 0%, 28%, 0.1) !important; /* Темная обводка */
  --tgui--plain_background: hsla(0, 0%, 67%, 0.08) !important;; /* Светлая фон */
  --tgui--plain_foreground: hsla(0,0%,100%,0.95) !important;; /* Светлый текст */
  --tgui--primary_code_highlight: #2990ff;
  --tgui--quartenary_bg_color: #f7f7f7; /* Четвертичный фон светлый */
  --tgui--safe_area_inset_bottom: 0px;
  --tgui--secondary_bg_color: #ffffff; /* Вторичный фон белый */
  --tgui--secondary_code_highlight: #e937ed;
  --tgui--secondary_fill: rgba(41,144,255,0.15);
  --tgui--secondary_hint_color: #78797e;
  --tgui--section_bg_color: #f0f0f0; /* Фон секций светлый */
  --tgui--section_header_text_color: #4a4a4a; /* Заголовки секций темнее */
  --tgui--segmented_control_active_bg: #eaeaea; /* Фон активных элементов */
  --tgui--skeleton: hsla(0,0%,0%,0.03);
  --tgui--subheadline1--font_size: 16px;
  --tgui--subheadline1--line_height: 21px;
  --tgui--subheadline2--font_size: 15px;
  --tgui--subheadline2--line_height: 20px;
  --tgui--subtitle_text_color: #4a4a4a; /* Подзаголовки темные */
  --tgui--surface_dark: rgba(240,240,240,0.85); /* Поверхности светлые */
  --tgui--surface_primary: hsla(0,0%,91%,0.95); /* Поверхности светлые */
  --tgui--tertiary_bg_color: #fafafa; /* Третичный фон светлый */
  --tgui--tertiary_code_highlight: #5ae536;
  --tgui--text_color: #000000; /* Основной текст черный */
  --tgui--text--font_size: 17px;
  --tgui--text--line_height: 22px;
  --tgui--title1--font_size: 28px;
  --tgui--title1--line_height: 34px;
  --tgui--title2--font_size: 24px;
  --tgui--title2--line_height: 28px;
  --tgui--title3--font_size: 20px;
  --tgui--title3--line_height: 25px;
  --tgui--toast_accent_color: #55a6ff;
  --tgui--white: #fff; /* Белый остается белым */
  --tgui--z-index--overlay: 3;
  --tgui--z-index--simple: 1;
  --tgui--z-index--skeleton: 2;
  --tooltip_background_dark: rgba(255,255,255,0.85); /* Фон тултипов светлый */
  
  /* Приоритетные переменные ниже */
  --tg-theme-subtitle-text-color: #4a4a4a !important;
  --tg-theme-section-bg-color: #f0f0f0 !important;
  --tg-theme-link-color: #00c2ed !important;
  --tg-theme-section-header-text-color: #4a4a4a !important;
  --tg-theme-header-bg-color: #e0e0e0 !important;
  --tg-theme-destructive-text-color: #ef5b5b !important;
  --tg-color-scheme: light !important;
  --tg-theme-bg-color: #f5f5f5 !important;
  --tg-theme-button-text-color: white !important;
  --tg-theme-button-color: black !important;
  --tg-theme-secondary-bg-color: #ffffff !important;
  --tg-theme-hint-color: #4a4a4a !important;
  --tg-theme-accent-text-color: #07D800 !important;
  --tg-theme-text-color: #000000 !important;
  --tg-viewport-height: 100vh!important;
  --tg-viewport-stable-height: 100vh!important;
}


.tabbar_active {
  color: var(--tg-theme-accent-text-color) !important;
}

.balance__button.tgui-c5545e0bc6105e48  {
  color: var(--tg-theme-accent-text-color) !important;
}

html {
  /*height: 100vh;
  overflow: hidden;*/
  background: var(--tg-theme-secondary-bg-color) !important;
}


.app {
  max-width: 600px;
  height: 100vh;
  overflow-y: auto;
  margin: auto;
  display: block;
}

#root > div {
    /*background: var(--tgui--secondary_bg_color);*/
    /*height: 100vh;*/
}

.tgui-b8dfba0b5c3d054c:hover {
    background: var(--tgui--bg_color) !important;
    border-radius: 12px;
}

.tgui-b8dfba0b5c3d054c {
  background: var(--tgui--bg_color) !important;
  border-radius: 12px;
}

.tgui-4b78bed6e925088e {
    overflow: hidden;
}

.balance__block {
  margin: 32px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:8px;
}

.balance__block__card {
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:8px;
}

.balance__block__amount {
  display: flex;
  align-items: center;
  gap: 8px;
}

.balance__block__bal {
  font-size: 46px;
  margin-top: 4px;
  font-weight: 700;
}
.balance__block__bal span {
  color: var(--tgui--hint_color);
}

.hint {
  font-size: 14px;
  color: var(--tgui--hint_color);
}

.topup__info {
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 14px;
  color: var(--tgui--hint_color);
}


.balance__block__title {
  font-size: 17px;
  font-weight: 600;
}

.cards__info__block {
  display: flex;
  flex-direction: column;
  gap: 8px
}

.card__info__block {
  padding: 12px 16px;
}

.cell__transactions__after {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.cell__transactions__after__amount {
  font-weight: 400;
  font-size: 17px;
  white-space: nowrap;
}

.cell__transactions__after__type {
  font-size: 15px;
  white-space: nowrap;
  color: var(--tgui--hint_color);
}

.GREEN .cell__transactions__after__type, .GREEN .cell__transactions__after__amount, .receive__trans {
  color: #31D158;
}


.card__block {
  width: 100%;
  height: 220px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.card__block__create {
  width: 274px;
  height: 172px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}


.card__block__id {
  color: #bcbcbc;
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  bottom: 15px;
  left:20px;
  display: flex;
  flex-direction: column;
  gap:1px;
}

.copy {
  margin-bottom: 5px;
  color: #FFFFFF80;
  font-size: 10px;

}

.card_number {
  font-weight: 600;
  color: white;
  font-size: 22px;
}

.card__block__img_text {
  font-family: "Montserrat", sans-serif !important;
  color: white !important;
  font-weight: 700;
  font-size: 18px;
}
.card__block__id__block_t {

  font-size: 10px;
}

.card__block__id__block_v {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 700;
  color:white;
}
.card__block__logo {
  position: absolute;
  bottom: 24px;
  width: 65px;
  height: 45px;
  right:15px;
}
.card__block__logo__create {
  position: absolute;
  bottom: 15px;
  width: 45px;
  height: 30px;
  right:15px;
}
.card__block__img {
  top:20px;
  left:20px;
  position: absolute;
}

.card__block__img_pay {
  position: absolute;
  top: 25px;
  width: 35px;
  height: 35px;
  right:20px;
}

.card__block__img_pay_create {
  position: absolute;
  top: 25px;
  width: 25px;
  height: 25px;
  right:20px;
}
.card_bg {
  position: absolute;
  top:0;
  left:0;
  right:0;
  z-index: 1;
  height: 135px;
  width: 100%;
}
.card_bg_full {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  z-index: 0;
  right:0;
  bottom:0;
}
.card__data {
  height: 115px;
}
/*
@media screen and (max-width: 450px) {
  .card_bg_full {
    height: 230px !important;
  }
  .card__data {
    height: 105px;
  }
} 

@media screen and (max-width: 410px) {
  .card_bg_full {
    height: 220px !important;
  }
  .card__data {
    height: 100px;
  }
} 

@media screen and (max-width: 400px) {
  .card_bg_full {
    height: 210px !important;
  }
  .card__data {
    height: 95px;
  }
} 

@media screen and (max-width: 380px) {
  .card_bg_full {
    height: 200px !important;
  }
  .card__data {
    height: 85px;
  }
} 

*/

.card__block__show {
  border-radius: 12px;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  gap:7px;
  color:#FFFFFF;
  position: absolute;
  bottom: 20px;
  background-color: #00000061;
}

.card_mask_show {
  position: absolute;
  bottom: 55px;
  font-size: 16px;
  color:white;
}

.card__block__show__text {
  font-size: 10px;
  white-space: nowrap;
  
}

.balance__block__title__bal {
  font-weight: 500;
  font-size: 17px;
}

.balance__block__bal__bal {
  font-weight: 700;
  font-size: 32px;
}

.balance__block__hint__bal {
  font-weight: 400;
  font-size: 17px;
  color: var(--tgui--hint_color);
}

.balance__block__img__bal {
  display: block;
  margin: auto;
  border-radius: 100%;
  width: 64px;
  height: 64px;
}

.transaction__user__name {
  font-weight: 600;
}


.transaction__user {
  background: var(--tgui--secondary_bg_color) !important;
  padding: 0;
}

.transaction__user:hover {
  background: var(--tgui--secondary_bg_color) !important;
}

.full_w {
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100vh;
}

.full_w .tgui-726846958fe7f4a0{
  flex-grow: unset !important;
}

.transaction__amount {
  font-size: 56px;
  font-weight: 700;
  display: block;
  line-height: 48px;
}

.transaction__amount span {
  font-size: 46px;
}

.transaction__date {
  color: var(--tgui--hint_color);
  font-size: 17px;
  margin-top: 34px;
}

.transaction__block {
  padding: 8px;
}

.transaction__buttons {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
}

.transaction__button {
  background-color: var(--tgui--bg_color) !important;
  align-items: start;
  padding: 0 16px;
  min-width: calc(100% / 2 - 4px);
  max-width: calc(100% / 2 - 4px);
}

.transaction__button__value {
  text-align: start !important;
  font-size: 17px;
  color: var(--tgui--text_color) !important;
}

.ordinary__text {
  color: var(--tgui--text_color) !important;
}

.transaction__button__title {
  color: var(--tgui--hint_color);
  font-size: 15px;
  text-align: start;
}

.go3958317564 {
  font-weight: bold;
  
  font-family: "SF Pro Display", "SF Pro", "Roboto", "Arial", sans-serif !important;
}

.go4109123758 {
  z-index: 1000000000000 !important; 
}

.pincode__layout {
  z-index: 1000000;
  position: fixed;
}

label {
  background-color: var(--tgui--secondary_bg_color) !important;
}


.card__block__id__FLEX {
  display: flex;
  align-items: center;
  gap:60px;
  margin-top: 10px;
}

.card__block__id__block {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap:1px
}

.icon_svg {
  color: var(--tgui--accent_text_color) !important;
  fill: var(--tgui--accent_text_color) !important;
}

.qr__bg {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}

.tgui-c0c9bf3b1a53a8a4 {
  max-width: 100% !important;
}

.vkuiIcon {
  color: var(--tgui--accent_text_color) !important;
}

.right__arrow{
  color: var(--tgui--hint_color) !important;
}

.card__block__id_show {
  position: absolute;
  bottom: 5px;
  left:20px;
  display: flex;
  flex-direction: column;
  gap:1px;
}

.warn .tgui-fc059ed3ac5799a6{
  white-space:unset !important;
}

.topup .tgui-45c5f45d3e9105f4{
 font-size: 19px !important;
}

.tr_number {
  font-size: 13px !important;
  font-weight: 400!important;
  color: var(--tgui--hint_color) !important; 
}

.tr_number_v {
  font-size: 13px !important;
  font-weight: 400!important;
  color: var(--tgui--text_color) !important; 
}

.tr_time dd{
  margin-top:16px !important;
}


.input {
  background-color: var(--tgui--bg_color) !important;
}

.tabs {
  border-radius: 9px !important;
  overflow: hidden !important;
}

.tabs .tgui-31f461ccfea23ec3, .tabs .tgui-16c6b1986a48e2b5  {
  border-radius:9px !important;
}

.tabs_block .tgui-4b78bed6e925088e{
  background: transparent !important;
}

.ref__baner .tgui-1c6d7865a76a19bc {
  white-space:initial !important;
} 

.ref__baner__input {
  background-color: var(--tg-theme-bg-color) !important;
}

.tabbar {
  position: fixed;
  bottom: 0;
  background-color: var(--tg-theme-bg-color);
  left:0;
  z-index: 10000;
  right:0;
}

.tabbar_inert {
  color: var(--tg-theme-hint-color) !important;
}
.tabbar_inert svg{
  color: var(--tg-theme-hint-color) !important;
}

.geft__card__banner {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 16px;
}

@keyframes opacityElement {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.main__button {
  position: fixed;
  bottom: 94px;
  background-color: var(--tg-theme-bg-color);
  left:0;
  margin: auto;
  width: calc(100% - 32px);
  z-index: 10000;
  right:0;
  animation: opacityElement 1s ease;
}

.main__button h6 {
  color: var(--tg-theme-text-color) !important;
}

div[role="dialog"] {
  z-index: 10000000;
}

.main__button__nav {
  display: flex;
  width: 100%;
  box-shadow: 0 0 0 1px var(--tgui--outline) !important;
  align-items: center;
  justify-content: center;
  height: 40px;
  
}

.main__button__nav:first-child {
  background-color: var(--tgui--plain_background);
}

.main__button__nav h6 {
  flex: unset;
  color: var(--tg-theme-text-color) !important ;
}
.main__button__nav svg {
  color: unset !important;
  
}

.pinInput__page {
  background-color: var(--tgui--secondary_bg_color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.pins {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 52px;
  margin-top:20px;
}

.pins .pin {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: var(--tgui--plain_background) ;
}

.pin__load {
  width: 13px;
  height: 13px;
}

.pins__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:12px
}
.pins__buttons__row {
  display: flex;
  align-items: center;
  gap:12px;
}

.pins__buttons__row div {
  width: 76px;
  background-color: var(--tgui--plain_background);
  border-radius: 100%;
  font-family: "SF Pro Display", "SF Pro", "Roboto", "Arial", sans-serif !important;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
}

.pin__transparent {
  background-color: transparent !important;
}


.active__pin {
  background-color: var(--tg-theme-text-color) !important;
}


.pinInput__page .tgui-14d79626209a204a {
  color: var(--tg-theme-text-color) !important;
}

@keyframes shake {
  0%, 100% {
      transform: translateX(0);
  }
  20%, 60% {
      transform: translateX(-10px);
  }
  40%, 80% {
      transform: translateX(10px);
  }
}


.pinInput__error .pins div {
  background-color: red !important;
  opacity: 0.5;
  animation: shake 0.5s ease;
}

.pinInput__page__forget {
  position: absolute;
  bottom:60px;
  text-align: center;
  width: 100%;
}

.backspace svg {
  color: unset !important;
}


@keyframes slideOut {
  0% {
    top: 10px ;
  }
  100% {
    top: -200px;
  }
}

@keyframes slideIn {
  0% {
    top: -200px;
  }
  100% {
    top: 10px !important;
  }
}



.snackbar {
  z-index: 10000000000; 
  bottom: unset !important;
  top:16px;
  
  animation: slideIn 0.7s ease forwards;
}

.snackbar span {
  color: var(--tgui--text_color) !important;
}

.snackbar__close {
  animation: slideOut 0.7s ease forwards;
}

.scan__icon {
  position: absolute;
  right:16px;
  color: var(--tg-theme-text-color) !important;
}

.iframe {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  border: unset;
}

@media (max-width: 768px) {
  .iframe {
      height: 100vh;
  }
}


.overview__blocks {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-theme-secondary-bg-color) !important;
  position: relative;
}

.overview__blocks__steps {
  position: absolute;
  top:10px;
  width: 100%;
  padding: 0 16px;
}
